import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { ISendReclamationService } from "@modules/reclamation/models/ISendReclamationService";
import AppError from "@utils/AppError";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FormQuery } from "./FormQuery";
import FormQueryValidationSchemaContact from "./ValidationYup/FormQueryValidationSchemaContact";
import { ModalSendSuccess } from "./Sections/ModalSendSuccess";
import {
  ReclamationUserProvider,
  useReclamationContext,
} from "../../../context/ReclamationContext/ReclamationUserContext";
import { useHistory } from "react-router-dom";
import ROUTES from "@config/routes";
import { Box } from "@material-ui/core";
import { addRefIdToLocalStorage } from "@utils/localStorageUtils";

const Reclamation: React.FC = () => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const sendReclamationService = iocContext.serviceContainer.get<
    ISendReclamationService
  >(Types.Reclamation.ISendReclamationService);

  const [modalOpen, setModalOpen] = useState(false);

  const { uploadedImages, setLoadingSend } = useReclamationContext();
  const history = useHistory();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const initalValues = {
    full_name: "",
    telephone_number: "",
    CNPJ: "",
    recordType: "",
    target_sector: "",
    observation: "",
    file: [],
  };

  const filesArray = uploadedImages.map((image) => image);

  const handleSubmit = async (values) => {
    try {
      setLoadingSend(true);

      const response = await sendReclamationService.execute({
        fullName: values.full_name,
        telephoneNumber: values.telephone_number,
        cnpj: values?.CNPJ?.split("-")[0],
        companyName: values?.CNPJ ? values.CNPJ.split("-")[1] : "",
        recordType: values.recordType,
        observation: values.observation,
        images: filesArray,
      });

      addRefIdToLocalStorage("refIdReclamationCreated", response.refId);

      openModal();
      setTimeout(function () {
        history.replace(ROUTES.USER_ROUTES.RECLAMATION_CONSULT);
      }, 3000);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Não foi possível enviar reclamação", {
        variant: "error",
      });
    } finally {
      setLoadingSend(false);
    }
  };

  return (
    <>
      {" "}
      <Box style={{ padding: "0px 3%" }}>
        <Formik
          initialValues={initalValues}
          onSubmit={handleSubmit}
          validationSchema={FormQueryValidationSchemaContact}
        >
          <Form>
            <FormQuery />
            <ModalSendSuccess open={modalOpen} closeModal={closeModal} />
          </Form>
        </Formik>
      </Box>
    </>
  );
};

const Container: React.FC = () => {
  return (
    <ReclamationUserProvider>
      <Reclamation />
    </ReclamationUserProvider>
  );
};

export default Container;
