import React, { createContext, useContext, useState } from "react";

import { useReclamationContext } from "@context/ReclamationContext/ReclamationUserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";

import { IFilterAllReclamationData } from "@modules/reclamation/dtos/IFilterAllReclamationData";
import { IFilterAllReclamationService } from "@modules/reclamation/models/IFilterAllReclamationService";

import { formatDateAndHourPTBR, maskCNPJ } from "@utils/index";
import AppError from "@utils/AppError";

import useDialogAlert from "@hooks/useDialogAlert";

import { Types } from "@ioc/types";

interface ILoad {
  dataFilterReclamation: boolean;
}

interface IFetch {
  consult: (formikValues, page?) => Promise<void>;
}

interface IReclamationConsultContext {
  reclamationUserContext: {
    totalCount;
    loadingDataFilterReclamation;
    tabActive;
  };
  reclamation: {
    load?: ILoad;
    fetch: IFetch;
  };
}

export const ITEMS_PER_PAGE = 10;
const ReclamationConsultContext = createContext<
  IReclamationConsultContext | undefined
>(undefined);
const ReclamationConsultProvider: React.FC = ({ children }) => {
  const {
    totalCount,
    tabActive,
    setDataQueryReclamationUser,
    setTotalCount,
    setFilterUser,
    rangeDate,
    setIdRef,
    idRef,
    setCNPJSelected,
    cnpjSelected,
  } = useReclamationContext();

  const [load, setLoad] = useState<ILoad | undefined>(undefined);

  const { snackbarError, snackbarSuccess, snackbarWarn } = useDialogAlert();
  const iocContext = useIoCContext();

  const fetchReclamation = async (formikValues, page?) => {
    try {
      if (!formikValues) return;
      setLoad({ ...load, dataFilterReclamation: true });
      const reclamationService = iocContext.serviceContainer.get<
        IFilterAllReclamationService
      >(Types.Reclamation.IFilterAllReclamationService);

      if (formikValues.ref_id) {
        setIdRef(formikValues.ref_id);
      }

      if (formikValues.cnpj) {
        setCNPJSelected(formikValues.cnpj);
      }

      const payloadFilterReclamation = {
        ref_id: formikValues.ref_id ? formikValues.ref_id : idRef,
        cnpj: formikValues.cnpj ? formikValues.cnpj : cnpjSelected,
        beginDate: rangeDate ? rangeDate[0] : formikValues.startDate,
        endDate: rangeDate ? rangeDate[1] : formikValues.endDate,
        status: tabActive === 0 ? "IN_PROGRESS" : "CLOSED",
        page: page > 1 ? page : 1,
        order: JSON.stringify([["updatedAt", "DESC"]]),
        limit: ITEMS_PER_PAGE,
      };

      setFilterUser(payloadFilterReclamation);

      const reclamationResponse = await reclamationService.execute(
        payloadFilterReclamation
      );
      setTotalCount(reclamationResponse?.total);

      const newData = reclamationResponse.content.map((item) => ({
        vid: item.refId,
        companyName: item.companyName,
        cnpj: item.cnpj ? maskCNPJ(item.cnpj) : "-",
        dateBegin: item.createdAt ? formatDateAndHourPTBR(item.createdAt) : "-",
        userRequest: item.fullName,
        id: item.id,
        status: item.status,
      }));

      const filterData: IFilterAllReclamationData = {
        content: newData,
        total: newData.length,
        page: page > 1 ? page : 1,
      };

      setDataQueryReclamationUser(filterData);
      // Note: Sinalizacao de mensagem apos interacao do usuario com a primeira consulta atraves do click no botao "Consultar"
      if (page === undefined) {
        const totalRecords = reclamationResponse?.total ?? 0;
        const message = getReclamationMessage(totalRecords);

        return totalRecords === 0
          ? snackbarWarn(message)
          : snackbarSuccess(message);
      }
    } catch (error) {
      if (error instanceof AppError) {
        return snackbarError(
          `Ocorreu um erro ao filtrar pela reclamação - ${error?.message}`
        );
      }
    } finally {
      setLoad({ ...load, dataFilterReclamation: false });
    }
  };

  const reclamationContext: IReclamationConsultContext = {
    reclamationUserContext: {
      tabActive,
      totalCount,
      loadingDataFilterReclamation: load?.dataFilterReclamation,
    },
    reclamation: {
      load: load,
      fetch: {
        consult: fetchReclamation,
      },
    },
  };

  return (
    <ReclamationConsultContext.Provider value={reclamationContext}>
      {children}
    </ReclamationConsultContext.Provider>
  );
};

const getReclamationMessage = (totalRecords: number) => {
  if (totalRecords === 0) {
    return "Filtro realizado com sucesso com 0 registros";
  }

  const recordWord = totalRecords === 1 ? "registro" : "registros";
  return `Filtro realizado com sucesso com ${totalRecords} ${recordWord}`;
};

const useReclamationConsultContext = () => {
  const context = useContext(ReclamationConsultContext);
  if (!context) {
    throw new Error(
      "useReclamationConsultContext must be used within a ReclamationConsultProvider"
    );
  }
  return context;
};

export { ReclamationConsultProvider, useReclamationConsultContext };
