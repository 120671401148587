import ButtonLinkGeneric from "@components/ButtonLinkGeneric/ButtonLinkGeneric";
import ROUTES from "@config/routes";
import { Typography, useTheme } from "@material-ui/core";
import { Grid, useMediaQuery } from "@mui/material";
import React from "react";

const OpenNewReclamation: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));

  return (
    <Grid
      container
      className="criar-nova-reclamacao"
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{ border: "0px solid red" }}
    >
      <Grid item sx={{ p: 2 }}>
        <Typography
          style={{
            color: "#3E3D3D",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize:
              isMobile || isTablet ? "2rem" : isTablet ? "2.5rem" : "2.4rem",
            marginLeft: isMobile || isTablet ? "10px" : "0px",
          }}
        >
          Fale conosco
        </Typography>
      </Grid>

      <Grid item>
        <ButtonLinkGeneric
          text={"Abrir novo registro"}
          link={ROUTES.USER_ROUTES.RECLAMATION}
        />
      </Grid>
    </Grid>
  );
};

export default OpenNewReclamation;
