import TabGeneric, { ILabelTab } from "@components/TabGeneric/TabGeneric";
import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import TableReclamationAll from "./TableReclamationAll";
import { useReclamationContext } from "@context/ReclamationContext/ReclamationUserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { IGetReclamationAdminService } from "@modules/reclamation/models/IGetReclamationAdminService";
import { downloadFile } from "@utils/index";
import { Grid } from "@mui/material";
import { Types } from "@ioc/types";

const TableReclamationIndex: React.FC = () => {
  const classes = useStyles();

  const {
    dataQueryReclamationUser,
    setTabActive,
    tabActive,
    totalCount,
    filterUser,
  } = useReclamationContext();

  const [hasLoadingReportDownload, setHasLoadingReportDownload] = useState(
    false
  );
  const iocContext = useIoCContext();
  const reclamationService = iocContext.serviceContainer.get<
    IGetReclamationAdminService
  >(Types.Reclamation.IGetReclamationAdminService);

  const handleChange = (_: React.ChangeEvent<{}>, newTab: number) => {
    setTabActive(newTab);
  };

  const TabsLabel: ILabelTab[] = [
    {
      label: "Registros em andamento",
      key: 0,
      count: tabActive === 0 ? totalCount : 0,
    },
    {
      label: "Registros finalizados",
      key: 1,
      count: tabActive === 1 ? totalCount : 0,
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  const handleReportDownload = async () => {
    try {
      setHasLoadingReportDownload(true);

      const [url, fileName] = await reclamationService.downloadReports(
        filterUser
      );
      downloadFile(url, fileName);
      setHasLoadingReportDownload(false);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Grid container sx={{ p: 0 }}>
      <Grid
        item
        xs={12}
        sx={{
          position: "relative",
          height: 0,
          top: 30,
          display: "flex",
          justifyContent: "end",
          mb: { xs: 0.2, md: 0.4, lg: 2 },
        }}
      >
        <Button
          className={classes.buttonDownloadReport}
          color="primary"
          onClick={() => handleReportDownload()}
          endIcon={
            hasLoadingReportDownload && (
              <CircularProgress color="inherit" size={20} />
            )
          }
          disabled={dataQueryReclamationUser.total === 0 ? true : false}
        >
          Baixar relatório completo
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TabGeneric
          tabActive={tabActive}
          handleChange={handleChange}
          optionsTabLabel={TabsLabel}
          alignTabs={"left"}
          style={{
            margin: "0px",
          }}
        >
          <TableReclamationAll
            queryDataReclamationUser={dataQueryReclamationUser}
            status={tabActive === 0 ? "IN_PROGRESS" : "CLOSED"}
          />
        </TabGeneric>
      </Grid>
    </Grid>
  );
};

export default TableReclamationIndex;

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonDownloadReport: {
      textTransform: "none",
      fontFamily: "Montserrat",
      fontWeight: 600,
      lineHeight: "46px",
      height: "3.8rem",
    },
  })
);
