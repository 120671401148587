import React from "react";
import { Avatar, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getInitialLettersName } from "@utils/index";

interface CustomAvatarProps {
  photo?: string;
  name: string;
  width?: string | number;
  height?: string | number;
  className?: string;
}

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: "#F8E1E9",
    color: "#fe511d",
    fontSize: "1.4rem",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
}));

const CustomAvatar: React.FC<CustomAvatarProps> = ({
  photo,
  name,
  width,
  height,
  className,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const avatarSize = isMobile ? 32 : 42;

  const classes = useStyles();

  return (
    <Avatar
      src={photo}
      alt={name}
      className={`${classes.avatar} ${className || ""}`}
      style={{ width: width || avatarSize, height: height || avatarSize }}
    >
      {!photo && getInitialLettersName(name)}
    </Avatar>
  );
};

export default CustomAvatar;
