import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  CircularProgress,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import AppError from "@utils/AppError";
import { capitalizeFirstLetterFullString, maskCNPJ } from "@utils/index";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useAppointmentPriceContext } from "./AppointmentPriceContext";
import { IQuery } from "./interface";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardDefault from "@components/CardDefault";
import { CheckListIcon } from "@components/Icons";
import { useHomeUserContext } from "@context/HomeUserContext";
import ProgressBar from "@components/ProgressBar";
import { WithdrawBasis } from "@utils/interfaces";

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const userState = useUserState();
  const appointmentContext = useAppointmentPriceContext();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IQuery>();

  const { CNPJDetails, CNPJDetailsLoad } = useHomeUserContext();
  const [progress, setProgress] = useState(10);
  const [branchesWithdrawBasis, setBranchesWithdrawBasis] = useState<
    WithdrawBasis[]
  >([]);

  const blockingbyHiddePrice = CNPJDetails?.hiddePrice ?? false;

  const getClientInfoService = iocContext.serviceContainer.get<
    IGetClientInfoService
  >(Types.Orders.IGetClientInfoService);

  useEffect(() => {
    async function fetch() {
      if (values.CNPJ === "") return;
      try {
        appointmentContext.setLoadingCustomerData(true);
        const CNPJData = await getClientInfoService.execute(values.CNPJ, true);
        appointmentContext.setDataCustomer(CNPJData);
        setFieldValue("freightType", CNPJData.freightType);
      } catch (error) {
        if (error instanceof AppError) {
          return enqueueSnackbar(error.message, { variant: error.variant });
        }
        enqueueSnackbar("Ocorreu um erro ao buscar dados do cliente", {
          variant: "error",
        });
      } finally {
        appointmentContext.setLoadingCustomerData(false);
        appointmentContext.setLoadingFirstQuery(false);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.CNPJ]);

  useEffect(() => {
    if (values.CNPJ !== "" && appointmentContext.dataCustomer) {
      setFieldValue("address", appointmentContext.dataCustomer.address[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.CNPJ, appointmentContext.dataCustomer]);

  useEffect(() => {
    appointmentContext.setDataCustomer(null);
    appointmentContext.setLoadingFirstQuery(true);
    setFieldValue("filialID", "");
    setFieldValue("withdrawBasisID", "");
    setFieldValue("withdrawBasisBranchName", "");
    setFieldValue("address", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.CNPJ]);

  const findWithdrawBasis = (withdrawBasisList, filialIdDefault) => {
    return withdrawBasisList.find((ele) => ele.filialID === filialIdDefault);
  };

  const filterBranchesWithdrawBasis = (withdrawBasisList, withdrawBasis) => {
    if (!withdrawBasis) return [];
    return withdrawBasisList.filter(
      (ele) => ele.branch === withdrawBasis.branch
    );
  };

  const updateFormFieldsInitial = (withdrawBasis) => {
    const concatenatedValue = `${withdrawBasis?.shippingPoint}-${withdrawBasis?.branchName}`;
    setFieldValue("withdrawBasisBranchName", withdrawBasis?.branchName ?? "");
    setFieldValue("withdrawBasisID", concatenatedValue ?? "");
    setFieldValue("filialID", withdrawBasis?.filialID ?? "");
  };

  useEffect(() => {
    if (
      appointmentContext.dataCustomer &&
      values.address &&
      values.CNPJ !== ""
    ) {
      const withdrawBasisInitial = findWithdrawBasis(
        appointmentContext.dataCustomer.withdrawBasis,
        values.address.filialIdDefault
      );

      updateFormFieldsInitial(withdrawBasisInitial);

      const listAllWithdrawBasis = filterBranchesWithdrawBasis(
        appointmentContext.dataCustomer.withdrawBasis,
        withdrawBasisInitial
      );

      setBranchesWithdrawBasis(listAllWithdrawBasis);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentContext.dataCustomer, values.address]);

  const renderCPNJ = useMemo(() => {
    return userState.listCNPJ.map((ele, idx) => (
      <MenuItem key={ele.CNPJ} value={ele.CNPJ} className={classes.itemSelect}>
        <Typography className={classes.itemSelectTitle}>
          {capitalizeFirstLetterFullString(ele.companyName)}
        </Typography>
        <Typography className={classes.itemSelectSubTitle}>
          {maskCNPJ(ele.CNPJ)}
        </Typography>
      </MenuItem>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.listCNPJ]);

  const renderAddress = useMemo(() => {
    if (!appointmentContext.dataCustomer) return null;
    return appointmentContext.dataCustomer.address.map((ele) => (
      <MenuItem key={ele.id} value={ele.id} className={classes.itemSelect}>
        {capitalizeFirstLetterFullString(ele.street)} -{" "}
        {capitalizeFirstLetterFullString(ele.city)} - {ele.UF}
      </MenuItem>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentContext.dataCustomer]);

  useEffect(() => {
    if (!CNPJDetailsLoad) return;
    do {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 10
        );
      }, 800);
      return () => {
        clearInterval(timer);
      };
    } while (CNPJDetailsLoad);
  }, [CNPJDetailsLoad]);

  return (
    <CardDefault>
      <Grid item xs={12} style={{ display: "flex", paddingBottom: "1.2rem" }}>
        {CNPJDetailsLoad && (
          <div style={{ width: "10vw" }}>
            <ProgressBar bgcolor={"#D91F05"} progress={progress} height={4} />
          </div>
        )}
        <Grid item xs={10}>
          <Typography className={classes.titleCard}>
            Consultar preços de produtos
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "1.6rem",
          }}
        >
          <CheckListIcon fontSize="small" />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={isMobile ? 12 : 3}
        alignItems="center"
        style={{ paddingLeft: isMobile ? "1rem" : "3rem" }}
      >
        <FormControl
          fullWidth
          disabled={!blockingbyHiddePrice}
          className={classes.formControl}
          variant="outlined"
          style={{}}
        >
          <InputLabel htmlFor="freightType" className={classes.inputLabel}>
            Razão social
          </InputLabel>
          <Select
            className={classes.customSelect}
            error={!!errors.CNPJ && !!touched.CNPJ}
            onBlur={() => setFieldTouched("CNPJ", true)}
            value={values.CNPJ}
            onChange={({ target }) => {
              setFieldValue("CNPJ", target.value);
            }}
            name="CNPJ"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            endAdornment={
              appointmentContext.loadingCustomerData && (
                <InputAdornment position="end">
                  <CircularProgress
                    style={{ width: 20, height: 20, marginRight: -5 }}
                  />
                </InputAdornment>
              )
            }
            IconComponent={ExpandMoreIcon}
          >
            {renderCPNJ}
          </Select>
        </FormControl>
        <FormHelperText error={!!errors.CNPJ && !!touched.CNPJ}>
          {!!touched.CNPJ && errors.CNPJ}
        </FormHelperText>
      </Grid>

      <Grid container item xs={isMobile ? 12 : 3} alignItems="center">
        <FormControl
          fullWidth
          disabled={
            !Boolean(values.CNPJ) || appointmentContext.loadingCustomerData
          }
          className={classes.formControl}
          variant="outlined"
        >
          <InputLabel className={classes.inputLabel}>Endereço</InputLabel>
          <Select
            className={classes.customSelect}
            error={!!errors.address && !!touched.address}
            onBlur={() => setFieldTouched("address", true)}
            onChange={({ target }) => {
              if (appointmentContext.dataCustomer) {
                const address = appointmentContext.dataCustomer.address.find(
                  (ele) => ele.id === target.value
                );
                setFieldValue("address", address);
                if (address?.filialIdDefault) {
                  setFieldValue("filialID", address.filialIdDefault);
                }
              }
            }}
            value={values.address ? values.address.id : ""}
            name="address"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            endAdornment={
              appointmentContext.loadingCustomerData && (
                <InputAdornment position="end">
                  <CircularProgress
                    style={{ width: 20, height: 20, marginRight: -5 }}
                  />
                </InputAdornment>
              )
            }
            IconComponent={ExpandMoreIcon}
          >
            {values.CNPJ && renderAddress}
          </Select>
        </FormControl>
        <FormHelperText error={!!errors.address && !!touched.address}>
          {!!touched.address && errors.address}
        </FormHelperText>
      </Grid>

      <Grid item xs={isMobile ? 12 : 3} container alignItems="center">
        <FormControl
          fullWidth
          disabled={
            !Boolean(values.CNPJ) || appointmentContext.loadingCustomerData
          }
          className={classes.formControl}
          variant="outlined"
        >
          <InputLabel className={classes.inputLabel}>Centro</InputLabel>
          <Select
            className={classes.customSelect}
            error={!!errors.withdrawBasisID && !!touched.withdrawBasisID}
            onBlur={() => setFieldTouched("withdrawBasisID", true)}
            value={values.withdrawBasisID}
            onChange={({ target }) => {
              const withdrawBasisID = target.value as string;
              const [shippingPoint, ...branchNameParts] = withdrawBasisID.split(
                "-"
              );
              const branchName = branchNameParts.join("-");

              const selectedItem = branchesWithdrawBasis.find(
                (ele) =>
                  ele.shippingPoint === shippingPoint &&
                  ele.branchName === branchName
              );

              if (selectedItem) {
                const concatenatedValue = `${selectedItem.shippingPoint}-${selectedItem.branchName}`;
                setFieldValue("withdrawBasisID", concatenatedValue);
                setFieldValue("filialID", selectedItem?.filialID);
              }
            }}
            name="withdrawBasisID"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            endAdornment={
              appointmentContext.loadingCustomerData && (
                <InputAdornment position="end">
                  <CircularProgress
                    style={{ width: 20, height: 20, marginRight: -5 }}
                  />
                </InputAdornment>
              )
            }
            IconComponent={ExpandMoreIcon}
          >
            {branchesWithdrawBasis && branchesWithdrawBasis.length > 0
              ? branchesWithdrawBasis.map((ele) => {
                  return (
                    <MenuItem
                      key={`${ele.shippingPoint}-${ele.branchName}`}
                      value={`${ele.shippingPoint}-${ele.branchName}`}
                      className={classes.itemSelect}
                    >
                      {`${ele.name} - ${ele.branchName}`}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
        <FormHelperText
          error={!!errors.withdrawBasisID && !!touched.withdrawBasisID}
        >
          {!!touched.withdrawBasisID && errors.withdrawBasisID}
        </FormHelperText>
      </Grid>

      <Grid item xs={isMobile ? 12 : 3} container alignItems="center">
        <FormControl
          fullWidth
          disabled={
            !Boolean(values.CNPJ) || appointmentContext.loadingCustomerData
          }
          className={classes.formControl}
          variant="outlined"
        >
          <InputLabel className={classes.inputLabel}> Frete</InputLabel>
          <Select
            className={classes.customSelect}
            aria-label="freightType"
            name="freightType"
            value={values.freightType}
            onChange={({ target }) =>
              setFieldValue("freightType", target.value)
            }
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            endAdornment={
              appointmentContext.loadingCustomerData && (
                <InputAdornment position="end">
                  <CircularProgress
                    style={{ width: 20, height: 20, marginRight: -5 }}
                  />
                </InputAdornment>
              )
            }
          >
            <MenuItem value="CIF">CIF</MenuItem>
            <MenuItem value="FOB">FOB</MenuItem>
          </Select>
        </FormControl>
        <FormHelperText error={!!errors.freightType && !!touched.freightType}>
          {!!touched.freightType && errors.freightType}
        </FormHelperText>
      </Grid>
    </CardDefault>
  );
};

export { FormQuery };

const useStyles = makeStyles(() =>
  createStyles({
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemSelectTitle: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      marginTop: 10,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    itemSelectSubTitle: {
      color: "#D5D1CB",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "46px",
      padding: 0,
      marginTop: "-1.6rem",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },
    iconsDisabled: {
      opacity: "0.12",
    },
    titlePage: { color: " #3E3D3D" },
    titleCard: {
      color: " #3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "24px",
      fontWeight: 700,
      paddingTop: "2rem",
      paddingBottom: "1rem",
      marginLeft: "2rem",
    },
    inputLabel: {
      position: "absolute",
      top: "-4px",
      left: "12px",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 700,
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      borderRadius: "8px",
    },
    formControl: {
      borderRadius: "8px",
      width: "408px",
      height: "50px",
    },
    containerButton: {
      display: "flex",
      justifyContent: "right",
      marginTop: "2rem",
    },
    styleButton: {
      marginRight: "2rem",
      textTransform: "none",
      borderRadius: "2px",
      background: "#D91F05",
      boxShadow: "5px 15px 30px 0px rgba(126, 126, 177, 0.10)",
      "&:hover": {
        background: "#D91F31",
      },
    },
  })
);
