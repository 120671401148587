import CardLayerActions from "@components/CardLayerActions";
import {
  CheckListIcon,
  EditIcon,
  RedTrashIcon,
  RightGrenIcon,
} from "@components/Icons";
import Layout from "@components/Layout";
import { useUserState } from "@context/UserContext";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import {
  capitalizeFirstLetterFullString,
  cutText,
  maskCNPJ,
} from "@utils/index";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { IContactUs } from "./interface";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ModalUploadImagem from "./Sections/ModalUploadImagem";
import PhoneTextField from "@components/PhoneTextField";
import { useReclamationContext } from "../../../context/ReclamationContext/ReclamationUserContext";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { Link as LinkRouter } from "react-router-dom";
import ROUTES from "@config/routes";
import { TiposDeRegistro } from "./models/ENUM_RECLAMATION";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "normal",
      paddingTop: "20px",
    },
    inputLabel: {
      position: "absolute",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "1.6rem",
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
    textfield: {
      width: "100%",
      fontFamily: "Montserrat",
      "& label": {
        // Controle de estilo antes de digitar
        color: "#3E3D3D",
        fontWeight: 400,
        fontSize: "1.6rem",
        borderRadius: "8px",
        opacity: "0.6",
      },
      // "& input.Mui-focused": { border: "2px solid blue" }, // Controle de estilo durante digitacao
      "& .MuiInputLabel-shrink": {
        // Controle de estilo apos campo digitado
        fontSize: "1.6rem",
        fontWeight: 700,
        color: "#3E3D3D",
        opacity: 1,
        backgroundColor: "#fff",
        paddingRight: 6,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        opacity: "0.6",
      },
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemSelectTitle: {
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    itemSelectSubTitle: {
      fontSize: "1.2rem",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      "&:focus": {
        backgroundColor: "#FFFF",
      },
      borderRadius: "8px",
    },
    formControl: {
      borderRadius: "8px",
      height: "56px",
      width: "100%",
    },
    gridInput: {
      marginRight: "2rem",
      marginBottom: "2rem",
      height: "50px",
    },
  })
);

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const userState = useUserState();
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const {
    uploadedImages,
    setUploadedImages,
    loadingSend,
  } = useReclamationContext();

  const handleOpenModalUpload = () => {
    setOpenModalUpload(true);
  };

  const handleCloseModal = () => {
    setOpenModalUpload(false);
  };

  const [currentLength, setCurrentLength] = useState(0);
  const LIMIT_CARACTER_OBSERVATION = 1000;

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    setErrors,
  } = useFormikContext<IContactUs>();

  const handleRemoveImage = (index: number) => {
    const updatedImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(updatedImages);
  };

  const renderCPNJ = () => {
    return userState.listCNPJ.map((ele, idx) => {
      return (
        <MenuItem
          key={ele.CNPJ}
          value={ele.CNPJ + "-" + ele.companyName}
          className={classes.itemSelect}
        >
          <Typography className={classes.itemSelectTitle}>
            {capitalizeFirstLetterFullString(ele.companyName)}
          </Typography>
          <Typography className={classes.itemSelectSubTitle}>
            {maskCNPJ(ele.CNPJ)}
          </Typography>
        </MenuItem>
      );
    });
  };

  const renderRecordTypes = () => {
    return TiposDeRegistro.map((type) => (
      <MenuItem
        key={type.value}
        value={type.value}
        className={classes.itemSelect}
      >
        <Typography
          className={classes.itemSelectTitle}
          style={{ fontWeight: "normal" }}
        >
          {type.label}
        </Typography>
      </MenuItem>
    ));
  };

  const handleOnChangeFullName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("full_name", event.target.value);
  };

  const handleOnChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("observation", event.target.value);
    const inputValue = event.target.value;
    setCurrentLength(inputValue.length);
  };

  const handleOnChangeTelephoneNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("telephone_number", event.target.value);
  };

  const handleClearForm = () => {
    setFieldValue("full_name", "");
    setFieldValue("observation", "");
    setFieldValue("telephone_number", "");
    setFieldValue("CNPJ", "");
    setFieldValue("recordType", "");
    setFieldValue("target_sector", "");
    setUploadedImages([]);
    // Resetar os erros dos campos
    setFieldTouched("");
    setFieldError("full_name", "");
    setFieldError("observation", "");
    setFieldError("telephone_number", "");
    setFieldError("CNPJ", "");
    setFieldError("recordType", "");
    setFieldError("target_sector", "");
    setErrors({});
  };

  return (
    <Layout>
      <Grid
        container
        style={{
          width: "auto",
          margin: isMobile || isTablet ? "10px" : "12px",
          padding: isMobile || isTablet ? "5px" : "12px",
        }}
      >
        <Grid item xs={12} md={12}>
          <Typography
            className={classes.title}
            style={{
              marginBottom: "30px",
              fontSize:
                isMobile || isTablet ? "2rem" : isTablet ? "2.5rem" : "2.4rem",
              marginLeft: isMobile || isTablet ? "10px" : "0px",
            }}
          >
            Fale conosco
          </Typography>
        </Grid>

        <CardLayerActions title="Dados do usuário" icon={CheckListIcon}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                marginBottom: isMobile || isTablet ? "25px" : "",
              }}
            >
              <TextField
                style={{
                  width: isMobile || isTablet ? "100%" : "calc(100% - 30px)",
                }}
                className={classes.textfield}
                label="Nome e sobrenome"
                variant="outlined"
                id="Nome e sobrenome"
                type="text"
                value={values.full_name}
                onChange={handleOnChangeFullName}
                onBlur={() => setFieldTouched("full_name", true)}
                error={touched.full_name && Boolean(errors.full_name)}
                helperText={touched.full_name && errors.full_name}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <PhoneTextField
                label="Telefone (+DDD)"
                name="telephone"
                value={values.telephone_number}
                onChange={handleOnChangeTelephoneNumber}
                onBlur={() => setFieldTouched("telephone_number", true)}
                error={
                  touched.telephone_number && Boolean(errors.telephone_number)
                }
                helperText={touched.telephone_number && errors.telephone_number}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              style={{ marginTop: isMobile || isTablet ? "25px" : "30px" }}
            >
              <FormControl
                fullWidth
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="freightType"
                  className={classes.inputLabel}
                  style={{ fontWeight: !!touched.CNPJ ? "bold" : "none" }}
                >
                  Razão social
                </InputLabel>
                <Select
                  className={classes.customSelect}
                  error={!!errors.CNPJ && !!touched.CNPJ}
                  onBlur={() => setFieldTouched("CNPJ", true)}
                  value={values.CNPJ}
                  onChange={({ target }) => {
                    setFieldValue("CNPJ", target.value);
                  }}
                  name="CNPJ"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  {renderCPNJ()}
                </Select>
              </FormControl>
              <FormHelperText
                error={!!errors.CNPJ && !!touched.CNPJ}
                style={{ marginLeft: "14px" }}
              >
                {!!touched.CNPJ && errors.CNPJ}
              </FormHelperText>
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              style={{ marginTop: isMobile || isTablet ? "25px" : "30px" }}
            >
              <FormControl
                fullWidth
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="recordType"
                  className={classes.inputLabel}
                  style={{ fontWeight: !!touched.recordType ? "bold" : "none" }}
                >
                  Tipo de registro
                </InputLabel>
                <Select
                  className={classes.customSelect}
                  error={!!errors.recordType && !!touched.recordType}
                  onBlur={() => setFieldTouched("recordType", true)}
                  value={values.recordType}
                  onChange={({ target }) => {
                    setFieldValue("recordType", target.value);
                  }}
                  name="recordType"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  {renderRecordTypes()}
                </Select>
              </FormControl>
              <FormHelperText
                error={!!errors.recordType && !!touched.recordType}
                style={{ marginLeft: "14px" }}
              >
                {!!touched.recordType && errors.recordType}
              </FormHelperText>
            </Grid>
          </Grid>
        </CardLayerActions>

        <CardLayerActions
          title="Motivo do registro"
          icon={EditIcon}
          marginTop="40px"
        >
          <Grid container>
            <Grid item xs={12}>
              <TextField
                className={classes.textfield}
                label="Descrição"
                variant="outlined"
                type="text"
                value={values.observation}
                onChange={handleOnChangeDescription}
                onBlur={() => setFieldTouched("observation", true)}
                error={touched.observation && Boolean(errors.observation)}
                helperText={touched.observation && errors.observation}
                multiline
                rows={6}
                inputProps={{
                  maxLength: LIMIT_CARACTER_OBSERVATION,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        position: "relative",
                        marginTop: "-89px",
                        opacity: "0.3",
                      }}
                    >
                      {currentLength}/
                      {LIMIT_CARACTER_OBSERVATION - currentLength}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {uploadedImages.length < 3 && (
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleOpenModalUpload}
                  style={{
                    color: "#D91F05",
                    background: "transparent",
                    fontFamily: "Montserrat",
                    borderRadius: "2px",
                    border: "none",
                    padding: "10px 20px",
                    marginTop: "10px",
                    boxShadow: "none",
                    fontWeight: "bold",
                    textTransform: "none",
                    fontSize: isMobile ? "12px" : "",
                  }}
                >
                  Anexar arquivo
                </Button>
              </Grid>
            )}

            <Grid container>
              <List>
                {uploadedImages.map((image, index) => (
                  <ListItem key={index}>
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Imagem ${index}`}
                      width="24px"
                      height="24px"
                      style={{ marginRight: "10px" }}
                    />
                    <ListItemText>
                      <Typography
                        style={{
                          fontSize: isMobile
                            ? "12px"
                            : isTablet
                            ? "13px"
                            : "16px",
                          fontFamily: "DM Sans",
                        }}
                      >
                        {cutText(image.name, isMobile ? 15 : 35)}
                      </Typography>
                    </ListItemText>
                    <RightGrenIcon
                      style={{ fontSize: "15px", marginLeft: "20px" }}
                    />
                    <IconButton onClick={() => handleRemoveImage(index)}>
                      <RedTrashIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "right",
                marginTop: isMobile ? "20px" : "30px",
              }}
            >
              <Grid
                item
                xs={6}
                md={1}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  component={LinkRouter}
                  to={ROUTES.USER_ROUTES.RECLAMATION_CONSULT}
                  type="button"
                  variant="contained"
                  onClick={handleClearForm}
                  style={{
                    color: "#D91F05",
                    background: "transparent",
                    fontFamily: "Montserrat",
                    borderRadius: "2px",
                    padding: isMobile ? "0px 40px" : "10px 30px",
                    border: "none",
                    boxShadow: "none",
                    fontWeight: "bold",
                    textTransform: "none",
                    fontSize: isMobile ? "12px" : "",
                  }}
                >
                  Cancelar
                </Button>
              </Grid>

              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginLeft: isMobile ? "4px" : "20px",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    fontFamily: "Montserrat",
                    borderRadius: "2px",
                    fontWeight: "bold",
                    textTransform: "none",
                    background: "#D91F05",
                    color: "#FFF",
                    padding: isMobile ? "10px 30px" : "10px 30px",
                    boxShadow: "none",
                    fontSize: isMobile ? "12px" : "",
                  }}
                  startIcon={
                    loadingSend && (
                      <CircularProgress
                        color="secondary"
                        style={{ height: "2rem", width: "2rem" }}
                      />
                    )
                  }
                >
                  {loadingSend ? "Enviando" : "Finalizar"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardLayerActions>

        {openModalUpload && (
          <ModalUploadImagem
            isOpenModal={openModalUpload}
            onClose={handleCloseModal}
          />
        )}
      </Grid>
    </Layout>
  );
};

export { FormQuery };
