import { createTheme } from "@mui/material/styles";

const themev5 = createTheme({
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif",
    fontSize: 16,
    body1: {
      fontSize: "1.4rem",
    },
  },
  palette: {
    primary: {
      main: "#D91F05",
    },
    background: {
      paper: "#fff",
    },
  },
});

export default themev5;
