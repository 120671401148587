import { IconButton, styled, useMediaQuery, useTheme } from "@material-ui/core";
import {
  FirstPage,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  LastPage,
} from "@material-ui/icons";
import { usePagination } from "@material-ui/lab";
import { Typography } from "@mui/material";
import { calcPagesQuantity } from "@utils/pagination";
import React from "react";

const List = styled("ul")({
  display: "flex",
  justifyContent: "end",
  listStyle: "none",
  padding: 0,
  margin: 0,
});

const Item = styled("li")((props: { selected: boolean }) => ({
  "& button": {
    outline: "none",
    cursor: "pointer",
    backgroundColor: props.selected ? "#FFFFFF" : "#F5F5F5",
    border: 0,
    color: props.selected ? "#D91F05" : "#3E3D3D",
    width: 32,
    height: 36,
    fontSize: 16,
    fontFamily: "Montserrat",
    borderRadius: 4,
    fontWeight: "bold",
    marginLeft: "1px",
  },
}));

export interface PaginationProps {
  onPageChange?: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
  pagesTotal?: number;
  showFirstButton?: boolean;
  showLastButton?: boolean;
  page?: number;
  showMilestones?: boolean;
  itemsPerPage?: number;
  totalItems?: number;
}

const ITEMS_PER_PAGE_DEFAULT = 10;
const TOTAL_ITEMS_DEFAULT = 0;
const TOTAL_PAGE_DEFAULT = 0;
const PaginationDynamic: React.FC<PaginationProps> = ({
  onPageChange,
  showFirstButton = false,
  showLastButton = false,
  showMilestones = false,
  page = TOTAL_PAGE_DEFAULT,
  itemsPerPage = ITEMS_PER_PAGE_DEFAULT,
  totalItems = TOTAL_ITEMS_DEFAULT,
  pagesTotal = calcPagesQuantity(itemsPerPage ?? 0, totalItems ?? 0),
}) => {
  const { items } = usePagination({
    page,
    count: pagesTotal,
    showFirstButton,
    showLastButton,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    if (onPageChange) onPageChange(event, newPage);
  };

  return (
    <nav
      style={{
        display: showMilestones ? "flex" : "block",
        justifyContent: "space-between",
      }}
    >
      {showMilestones && (
        <MilestonesDisplay
          page={page}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
        />
      )}
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children: React.ReactNode = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <div
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                ...
              </div>
            );
          } else if (type === "page") {
            children = (
              <button
                type="button"
                disabled={item.disabled}
                onClick={(e) => {
                  handleChangePage(e, (page ?? 0) - 1);
                  item.onClick(e);
                }}
                style={{
                  fontSize: isMobile ? "11px" : "",
                  width: isMobile ? "25px" : "",
                }}
              >
                {page}
              </button>
            );
          } else if (type === "first" || type === "last") {
            children = (
              <IconButton
                disabled={item.disabled}
                type="button"
                onClick={(e) => {
                  handleChangePage(e, (page ?? 0) - 1);
                  item.onClick(e);
                }}
                style={{
                  width: isMobile ? "25px" : "",
                }}
              >
                {type === "first" ? <FirstPage /> : <LastPage />}
              </IconButton>
            );
          } else {
            const buttonType =
              type === "next" ? (
                <KeyboardArrowRightRounded />
              ) : (
                <KeyboardArrowLeftRounded />
              );

            children = (
              <IconButton
                disabled={item.disabled}
                type="button"
                onClick={(e) => {
                  handleChangePage(e, (page ?? 0) - 1);
                  item.onClick(e);
                }}
                style={{
                  width: isMobile ? "25px" : "",
                }}
              >
                {buttonType}
              </IconButton>
            );
          }

          return (
            <Item key={index} selected={selected}>
              {children}
            </Item>
          );
        })}
      </List>
    </nav>
  );
};

export default PaginationDynamic;

interface MileStoneProps extends PaginationProps {
  page: number;
  itemsPerPage: number;
  totalItems: number;
}

const MilestonesDisplay: React.FC<MileStoneProps> = ({
  page,
  itemsPerPage,
  totalItems,
}) => {
  const theme = useTheme();

  const goldHighlightColor = theme.palette.secondary.main;
  const firstItemIndex = page === 1 ? 0 : (page - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(page * itemsPerPage, totalItems);

  const showItems =
    totalItems === 0
      ? "0"
      : page === 1
      ? `${firstItemIndex + 1}`
      : `${firstItemIndex}-${lastItemIndex}`;

  return (
    <span>
      <Typography variant="body1" component="span">
        <strong>Mostrando itens </strong>
        <strong style={{ color: goldHighlightColor }}>{showItems}</strong>
      </Typography>
      <Typography variant="body1" component="span">
        Total de {totalItems}
      </Typography>
    </span>
  );
};
