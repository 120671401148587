import React, { useEffect, useState } from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography,
  Button,
} from "@material-ui/core";
import CardLayerActions from "@components/CardLayerActions";
import {
  CheckListIcon,
  CloseIconRadiusIcon,
  EditIcon,
} from "@components/Icons";
import { Container } from "@mui/material";
import TittleAndSubTitle from "@components/TittleAndSubTitle/TitleAndSubTitle";
import { useReclamationContext } from "@context/ReclamationContext/ReclamationUserContext";
import {
  capitalizeFirstLetterFullString,
  cutText,
  performBRPhoneMask,
} from "@utils/index";
import ButtonLinkGeneric from "@components/ButtonLinkGeneric/ButtonLinkGeneric";
import ROUTES from "@config/routes";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { IGetOneReclamationUserService } from "@modules/reclamation/models/IGetOneReclamationUserService";
import { Types } from "@ioc/types";
import { enqueueSnackbar } from "notistack";
import AppError from "@utils/AppError";
import { Skeleton } from "@material-ui/lab";
import { Modal, Backdrop } from "@material-ui/core";
import { List, ListItem, ListItemText, IconButton } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import { getTypeRecordInPTBR } from "@pages/User/Reclamation/models/ENUM_RECLAMATION";

const DetailsReclamationByUser: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const iocContext = useIoCContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indexImage, setIndexImage] = useState(0);

  const handleImageClick = (index) => {
    setIsModalOpen(true);
    setIndexImage(index);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const {
    setDetailReclamationUser,
    detailReclamationUser,
  } = useReclamationContext();

  const responseDataOneReclamationUser = iocContext.serviceContainer.get<
    IGetOneReclamationUserService
  >(Types.Reclamation.IGetOneReclamationUserService);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target === document.querySelector(".MuiBackdrop-root")) {
        handleClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    async function fetch() {
      try {
        const responseDataOneReclamation = await responseDataOneReclamationUser.execute();
        setDetailReclamationUser(responseDataOneReclamation);
      } catch (error) {
        if (error instanceof AppError) {
          return enqueueSnackbar(error.message, { variant: error.variant });
        }
        enqueueSnackbar(
          "Não foi possível carregar a reclamação. Entre em contato conosco na funcionalidade de Abrir Novo Registro na tela anterior.",
          {
            variant: "error",
          }
        );
      }
    }
    fetch();
  }, [responseDataOneReclamationUser, setDetailReclamationUser]);

  const imageByIndexSelected = detailReclamationUser.images?.map(
    (cont) => cont.url
  )[indexImage];
  const imageAltNameByIndexSelected = detailReclamationUser.images?.map(
    (cont) => cont.originalName
  )[indexImage];

  const handleDownload = (url: string, originalName) => {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = originalName;
    anchor.click();
  };

  return (
    <Container style={{ marginTop: "40px" }}>
      <Grid item xs={12} md={12}>
        <Typography
          className={classes.title}
          style={{
            marginBottom: "30px",
            fontSize:
              isMobile || isTablet ? "2rem" : isTablet ? "2.5rem" : "2.4rem",
            marginLeft: isMobile || isTablet ? "10px" : "0px",
          }}
        >
          Detalhes de registro de atendimento ao cliente
        </Typography>
      </Grid>
      <CardLayerActions title="Identificação do cliente" icon={CheckListIcon}>
        <Grid container>
          <Grid md={3} xs={6} className={classes.gridContainerTitles}>
            {!detailReclamationUser.fullName &&
            detailReclamationUser.fullName !== null ? (
              <Skeleton variant="rect" width={"80%"} height={"40px"} />
            ) : (
              <TittleAndSubTitle
                title={"Nome e sobrenome"}
                subTitle={
                  detailReclamationUser.fullName
                    ? capitalizeFirstLetterFullString(
                        detailReclamationUser.fullName
                      )
                    : "-"
                }
                color={"#3E3D3D"}
                opacity="0.80"
              />
            )}
          </Grid>
          <Grid md={3} xs={6} className={classes.gridContainerTitles}>
            {!detailReclamationUser.telephoneNumber &&
            detailReclamationUser.telephoneNumber !== null ? (
              <Skeleton variant="rect" width={"80%"} height={"40px"} />
            ) : (
              <TittleAndSubTitle
                title={"Número de telefone"}
                subTitle={
                  detailReclamationUser.telephoneNumber
                    ? performBRPhoneMask(detailReclamationUser.telephoneNumber)
                    : "-"
                }
                color={"#3E3D3D"}
                opacity="0.80"
              />
            )}
          </Grid>
          <Grid md={3} xs={6} className={classes.gridContainerTitles}>
            {!detailReclamationUser.cnpj &&
            detailReclamationUser.cnpj !== null ? (
              <Skeleton variant="rect" width={"80%"} height={"40px"} />
            ) : (
              <TittleAndSubTitle
                title={"Razão social"}
                subTitle={
                  detailReclamationUser.cnpj
                    ? capitalizeFirstLetterFullString(
                        detailReclamationUser.companyName
                      )
                    : "-"
                }
                color={"#3E3D3D"}
                opacity="0.80"
              />
            )}
          </Grid>

          <Grid md={6} xs={6} className={classes.gridContainerTitles}>
            {!detailReclamationUser.recordType &&
            detailReclamationUser.recordType !== null ? (
              <Skeleton variant="rect" width={"80%"} height={"40px"} />
            ) : (
              <TittleAndSubTitle
                title={"Tipo de registro"}
                subTitle={
                  detailReclamationUser.recordType
                    ? getTypeRecordInPTBR(detailReclamationUser.recordType)
                    : detailReclamationUser.recordType
                }
                color={"#3E3D3D"}
                opacity="0.80"
              />
            )}
          </Grid>

          <Grid
            xs={12}
            className={classes.gridContainerTitles}
            style={{
              paddingRight: isMobile ? "20px" : isTablet ? "25px" : "60px",
            }}
          >
            {!detailReclamationUser.observation &&
            detailReclamationUser.observation !== null ? (
              <Skeleton variant="rect" width={"80%"} height={"40px"} />
            ) : (
              <TittleAndSubTitle
                title={"Descrição"}
                subTitle={
                  detailReclamationUser.observation
                    ? detailReclamationUser.observation
                    : "-"
                }
                color={"#3E3D3D"}
                opacity="0.80"
                paddingTop={"20px"}
                lineHeight={"1.7em"}
              />
            )}
          </Grid>

          <Grid
            container
            style={{
              border: "2px solid #e3e1e1",
              borderStyle: "dotted",
              borderRadius: "8px",
              padding: "20px",
              borderSpacing: "6px",
            }}
          >
            <Grid item xs={12}>
              <TittleAndSubTitle
                title={imageByIndexSelected ? "Imagens Anexadas" : ""}
                subTitle={
                  imageByIndexSelected
                    ? "Clique na imagem para ampliar"
                    : "Sem imagem anexada."
                }
                color={"#3E3D3D"}
                opacity="0.80"
                paddingTop={imageByIndexSelected ? "10px" : ""}
                lineHeight={"1.7em"}
              />
            </Grid>

            <Grid container style={{ marginTop: "30px" }}>
              <List>
                {detailReclamationUser.images?.map((count, index) => (
                  <ListItem>
                    <button
                      style={{
                        border: "none",
                        background: "none",
                      }}
                      onClick={() => handleImageClick(index)}
                    >
                      <img
                        key={index}
                        src={count.url}
                        alt={count.originalName}
                        style={{
                          textAlign: "center",
                          maxWidth: "30px",
                          height: "30px",
                          cursor: "pointer",
                          marginRight: "15px",
                        }}
                      />
                    </button>
                    <ListItemText>
                      <Typography
                        style={{
                          fontSize: isMobile
                            ? "11px"
                            : isTablet
                            ? "14px"
                            : "14px",
                          fontFamily: "DM Sans",
                          marginLeft: "0px",
                          marginRight: "10px",
                        }}
                      >
                        {cutText(
                          count.originalName,
                          isMobile ? 18 : isTablet ? 45 : 60
                        )}
                      </Typography>
                    </ListItemText>
                    <IconButton
                      onClick={() =>
                        handleDownload(count.url, count.originalName)
                      }
                    >
                      <DownloadIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          {!detailReclamationUser.actionTaken && (
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                paddingTop: "30px",
              }}
            >
              <ButtonLinkGeneric
                link={ROUTES.USER_ROUTES.RECLAMATION_CONSULT}
                text="Voltar"
              />
            </Grid>
          )}
        </Grid>
      </CardLayerActions>

      {detailReclamationUser.actionTaken && (
        <Grid
          style={{
            marginTop: isMobile ? "15px" : isTablet ? "20px" : "30px",
            marginBottom: "30px",
          }}
        >
          <CardLayerActions title="Ação tomada" icon={EditIcon}>
            <Grid container>
              <Grid
                md={12}
                xs={12}
                className={classes.gridContainerTitles}
                style={{
                  paddingRight: isMobile ? "20px" : isTablet ? "25px" : "60px",
                }}
              >
                {!detailReclamationUser.actionTaken &&
                detailReclamationUser.actionTaken !== null ? (
                  <Skeleton variant="rect" width={"80%"} height={"100px"} />
                ) : (
                  <TittleAndSubTitle
                    title={""}
                    subTitle={
                      detailReclamationUser.actionTaken
                        ? detailReclamationUser.actionTaken
                        : "Não há nenhuma ação tomada."
                    }
                    color={"#3E3D3D"}
                    lineHeight={"1.7em"}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ButtonLinkGeneric
                link={ROUTES.USER_ROUTES.RECLAMATION_CONSULT}
                text="Voltar"
              />
            </Grid>
          </CardLayerActions>
        </Grid>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grid className={classes.paper} container>
          <Grid xs={12} item style={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={() => handleClose()}
              className={classes.closeButtonTop}
            >
              <CloseIconRadiusIcon style={{ width: "14px", height: "14px" }} />
            </Button>
          </Grid>
          <Grid xs={12} item>
            <img
              id={"transition-modal-title"}
              src={imageByIndexSelected}
              alt={imageAltNameByIndexSelected}
              className={classes.imgModal}
            />
          </Grid>
        </Grid>
      </Modal>
    </Container>
  );
};

export default DetailsReclamationByUser;

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainerTitles: {
      marginBottom: "40px",
    },
    title: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "normal",
      paddingTop: "20px",
    },
    modal: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "60%",
      height: "500px",
      maxHeight: "500px",
      boxShadow: "none",
      marginTop: "10px",
      marginBottom: "auto",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxHeight: "700px",
      paddingBottom: "30px",
    },
    closeButtonTop: {
      backgroudn: "transparent",
      paddingTop: "20px",
      paddingBottom: "20px",
      borderRadius: "30px",
    },
    imgModal: {
      width: "100%",
      maxHeight: "500px",
      objectFit: "contain",
      padding: "10px",
    },
  })
);
