import { endOfMonth, startOfMonth } from "date-fns";
import { IReclamationFormValues } from "./interface";

export const initialValuesFomik: IReclamationFormValues = {
  ref_id: "",
  cnpj: [],
  startDate: startOfMonth(new Date()),
  endDate: endOfMonth(new Date()),
  date_range: [startOfMonth(new Date()), endOfMonth(new Date())],
};
